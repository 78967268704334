import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

class AbeGarvinPage extends React.Component {
  render() {
    const description = `Abe Garvin is a member of Old Man Time and one of the cohosts of Old Man Pod, a comedy podcast. He may be related to Bryan Packman.`;

    return (
      <Layout location={this.props.location} title={this.props.data.site.siteMetadata.title}>
        <SEO title="Abe Garvin" description={description} featuredImage={this.props.data.file.publicURL} />
        <article>
          <header>
            <h1>Abe Garvin</h1>
            <Img fluid={this.props.data.file.childImageSharp.fluid} />
          </header>
          <p>
            Abe Garvin is one of the cohosts of Old Man Pod. Exact age is not known, but many speculate that he{`'`}s
            upwards of 500 years old. Abe{`'`}s foremost achievements include sleeping at the radio station, 17
            marriages, and generally being less confused than his cohost, Burt.
          </p>
          <p>
            Abe is not related to{' '}
            <a href="https://www.bryanpackman.com" rel="noopener noreferrer" target="_blank">
              Bryan Packman
            </a>
            .
          </p>
        </article>
      </Layout>
    );
  }
}

export default AbeGarvinPage;

export const pageQuery = graphql`
  query {
    file(absolutePath: { regex: "/abe-bio.jpg/" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
